.footer {
  background-color: #111;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4rem 0 2rem;
  color: rgba(255, 255, 255, 0.7);
}

.footer-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 3rem;
  margin-bottom: 3rem;
}

.footer-section h3 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
  letter-spacing: -0.01em;
}

.footer-section nav {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.footer-section a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.2s ease;
  width: fit-content;
}

.footer-section a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.footer-contact p {
  margin: 0.5rem 0;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.6);
}

.footer-contact a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  transition: color 0.2s ease;
}

.footer-contact a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.5);
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-location {
  color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 768px) {
  .footer {
    padding: 3rem 0 1.5rem;
  }

  .footer-main {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .footer-main {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
