.card {
  position: relative;
  flex: 0 0 320px;
  width: 320px;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 
    0 20px 40px rgba(0,0,0,0.3),
    0 0 0 1px rgba(255,255,255,0.05);
}

.card .card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-out;
}

.card:hover .card-image {
  transform: scale(1.05);
}

.card::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    transparent 0%,
    rgba(0,0,0,0.7) 50%,
    rgba(0,0,0,0.95) 100%
  );
  z-index: 1;
}

.card .card-header {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  font-weight: 400;
  padding: 0.5rem 0.75rem;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  border-radius: 6px;
  z-index: 2;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  z-index: 2;
}

.card .card-title {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.95);
  margin-bottom: 0.75rem;
}

.card .card-description {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  max-width: 90%;
}

/* Card accent styling */
.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(0,181,173,0) 0%,
    rgba(0,181,173,0.3) 50%,
    rgba(0,181,173,0) 100%
  );
  z-index: 3;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.card:hover::before {
  opacity: 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .card {
    flex: 0 0 280px;
    width: 280px;
    height: 360px;
  }

  .card-content {
    padding: 1.5rem;
  }

  .card .card-title {
    font-size: 1.15rem;
  }

  .card .card-description {
    font-size: 0.9rem;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .card,
  .card-image {
    transition: none;
  }
}

/* High Contrast Mode */
@media (prefers-contrast: more) {
  .card {
    border: 2px solid #fff;
  }
  
  .card-header {
    background-color: #000;
    color: #fff;
  }
  
  .card-description {
    color: #fff;
  }
}
