/* HERO SCROLLER */
.hero-scroller {
  position: relative;
  width: 100%;
  height: 84vh;
  overflow: hidden;
  background-color: #000;
  touch-action: pan-y pinch-zoom;
}

/* Container */
.hero-container {
  position: relative;
  display: flex;
  width: calc(80vw * 3);
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: grab;
  will-change: transform;
}

.hero-container:active {
  cursor: grabbing;
}

/* Slide */
.hero-slide {
  position: relative;
  flex: 0 0 80vw;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  transform-origin: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.hero-slide > div {
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 12px 24px rgba(0,0,0,0.3);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), 
              box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hero-slide.active > div {
  transform: scale(1.02);
  box-shadow: 0 16px 32px rgba(0,0,0,0.5);
}

/* Video */
.hero-slide video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.95;
  transition: opacity 0.3s ease;
}

.hero-slide.active video {
  opacity: 1;
}

/* Loading State */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #00b5ad;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Content */
.banner-content {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  color: #fff;
  z-index: 2;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1),
              transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.hero-slide.active .banner-content {
  opacity: 1;
  transform: translateY(0);
}

.banner-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0,0,0,0.8);
}

.banner-desc {
  font-size: 1rem;
  line-height: 1.4;
  color: #ddd;
  text-shadow: 0 1px 3px rgba(0,0,0,0.6);
  max-width: 600px;
}

/* Navigation Dots */
.hero-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  margin: 0 auto;
  padding: 1rem 0;
}

.dot {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #444;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border: none;
  padding: 0;
}

.dot:hover {
  transform: scale(1.1);
  background-color: #666;
}

.dot.active {
  background-color: #666;
}

.dot:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 181, 173, 0.5);
}

.dot-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  border-radius: 50%;
  background-color: #00b5ad;
  transition: width 0.1s linear, height 0.1s linear;
}

/* Progress Bar */
.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: #00b5ad;
  transition: width 0.1s linear;
  z-index: 3;
}

/* Responsive */
@media (max-width: 768px) {
  .hero-scroller {
    height: 70vh;
  }

  .hero-slide {
    padding: 0.5rem;
    flex: 0 0 85vw;
  }

  .banner-title {
    font-size: 1.5rem;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .hero-slide,
  .banner-content,
  .dot-progress,
  .hero-container {
    transition: none;
  }
}
