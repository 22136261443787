/* NAVBAR */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  position: relative;
  z-index: 2000; 
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left img {
  height: 40px;
  width: auto;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right a {
  margin-left: 2rem;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.navbar-right a:hover {
  color: #00b5ad;
}

/* Mobile Menu Button */
.mobile-menu-btn {
  display: none;
  background: none;
  border: none;
  color: #fff;
  padding: 0.5rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.mobile-menu-btn:hover {
  color: #00b5ad;
}

/* RESPONSIVE STYLES */
@media (max-width: 768px) {
  .mobile-menu-btn {
    display: flex;
    align-items: center;
  }

  .navbar-right {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #000;
    padding: 1rem 0;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #333;
    z-index: 1000;
  }

  .navbar-right.active {
    display: flex;
  }

  .navbar-right a {
    margin: 0.5rem 0;
  }
}

/* ACCESSIBILITY */
@media (prefers-reduced-motion: reduce) {
  .navbar-right a {
    transition: none;
  }
}
