/* RESET & BASE STYLES */
html, body {
  margin: 0;
  padding: 0;
  background-color: #111;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  background: linear-gradient(120deg, #111 0%, #1a1a1a 100%);
  color: #fff;
  line-height: 1.5;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overscroll-behavior: none;         
  overscroll-behavior-y: none;       
  -webkit-overflow-scrolling: auto;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

/* CONTAINER */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* SECTION STYLES */
.section-title {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin: 0;
  color: rgba(255, 255, 255, 0.95);
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.section-title-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* CIRCLE BUTTON STYLES */
.circle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.08);
  border: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  transition: transform 0.15s ease;
  flex-shrink: 0;
}

.circle-button:active {
  transform: scale(0.94);
}

.circle-button svg {
  width: 20px;
  height: 20px;
}

.circle-button:focus {
  outline: none;
}

.circle-button:focus-visible {
  outline: 2px solid rgba(255, 255, 255, 0.2);
  outline-offset: 2px;
}

/* SECTION LAYOUTS */
.card-section {
  padding: 4rem 0;
  position: relative;
}

.card-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2rem);
  max-width: 1200px;
  height: 1px;
  background: linear-gradient(90deg, 
    rgba(255,255,255,0) 0%, 
    rgba(255,255,255,0.07) 50%, 
    rgba(255,255,255,0) 100%
  );
}

/* CARD GRID */
.card-grid {
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  padding: 0.5rem 0;
  scroll-padding: 1rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-behavior: smooth;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 2rem,
    black calc(100% - 2rem),
    transparent
  );
}

.card-grid::-webkit-scrollbar {
  display: none;
}

/* COMPANY SECTION */
#company p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.85);
  margin-top: 2rem;
}

/* ANIMATIONS */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* RESPONSIVE STYLES */
@media (max-width: 768px) {
  .section-title {
    font-size: 1.5rem;
  }
  
  .card-section {
    padding: 2rem 0;
  }

  .circle-button {
    width: 36px;
    height: 36px;
  }

  .circle-button svg {
    width: 18px;
    height: 18px;
  }

  .section-title-wrapper {
    gap: 0.75rem;
  }
}

/* ACCESSIBILITY */
@media (prefers-reduced-motion: reduce) {
  .circle-button {
    transition: none;
  }

  .scroll-behavior: auto;
}
